import styled from 'styled-components';
import { Link } from 'gatsby';
import { mobileS, tablet, desktop } from '../responsive';

export const Container = styled.div`
  font-family: oswald;
  margin-bottom: 50px;
  @media screen and (min-width: 320px) {
    margin-bottom: 0;
  }
`;
export const OrderTabContainer = styled.div`
  font-family: oswald;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  padding: 20px;
  ${mobileS({ padding: '10px' })}
`;

export const Title = styled.h1`
  font-weight: 300;
  text-align: center;
  font-family: oswald;
  margin-bottom: 20px;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  ${mobileS({
    padding: '5px',
  })}
`;
export const OrderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  ${mobileS({
    padding: '5px',
  })}
`;

export const TopButton = styled(Link)`
  background: #1d9682;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  font-weight: 300;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 5px 30px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: "center";
  &:hover {
    transition: all 0.2sease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
  ${mobileS({
    padding: '5px 10px',
  })}
`;
export const TopButton2 = styled.button`
  background: #808080;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  font-weight: 300;
  outline: none;
  border: none;
  //cursor: pointer;
  padding: 5px 30px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: "center";
  // &:hover {
  //   transition: all 0.2sease-in-out;
  //   background: #f5f5f5;
  //   color: #205072;
  // }
  ${mobileS({
    padding: '5px 10px',
  })}
`;
export const TopButton3 = styled.button`
  background: #1d9682;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  font-weight: 300;
  outline: none;
  border: none;
  //cursor: pointer;
  padding: 5px 30px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: "center";
  &:hover {
    transition: all 0.2sease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
  ${mobileS({
    padding: '5px 10px',
  })}
`;

export const PrescriptionImg = styled.img`
  @media screen and (min-width: 320px) {
    width: 100%;
  }
`;

export const TopTexts = styled.div`
  ${mobileS({ display: 'none' })}
`;
export const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobileS({ flexDirection: 'column', margin: '1em 0' })}
  ${tablet({ flexDirection: 'row' })}
  ${desktop({ flexDirection: 'row' })}
`;
export const CartTabBottom = styled.div`
  display: flex;
  width: 72%;
  justify-content: center;
  align-items: center;
  margin: auto;
  ${mobileS({ flexDirection: 'column' })}
  ${tablet({ flexDirection: 'row' })}
  ${desktop({ flexDirection: 'row' })}
`;
export const OrderTabWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${mobileS({ flexDirection: 'column' })}
  ${tablet({ flexDirection: 'row' })}
  ${desktop({ flexDirection: 'row' })}
`;

export const Info = styled.div`
  flex: 2.5;
`;
export const OrderTabInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
`;

export const Product = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobileS({ flexDirection: 'column' })}
  ${tablet({ flexDirection: 'row' })}
  ${desktop({ flexDirection: 'row' })}
`;
export const OrderTabProduct = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  ${mobileS({ flexDirection: 'column' })}
  ${tablet({ flexDirection: 'row' })}
  ${desktop({ flexDirection: 'row' })}
  &:hover {
    box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  }
`;

export const ProductDetail = styled.div`
flex: 2;
display: flex;
flex-direction: column;
@media screen and (min-width: 768px) {
  flex-direction: row;
}
`;

export const Image = styled.img`
  width: 200px;
`;

export const Details = styled.div`
  padding: 1em;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;  
`;

export const OrderTabProduct2 = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
`;

export const ProductDetail2 = styled.div`
  background: #1d9682;
  max-width: 300px;
  max-height: 300px;
  border-radius: 5px;
  margin: 10px;
  //height: 100%;
  &:hover {
    box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  } 
`;

export const Details2 = styled.div`
  padding: 1em;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
`;

export const ProductName = styled.span``;

export const ProductId = styled.span``;

export const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const ProductSize = styled.span``;

export const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ProductAmount = styled.div`
  font-size: 24px;
  width: 70px;
  margin: 5px;
  ${mobileS({ margin: '5px 15px' })}
`;

export const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 300;
  ${mobileS({ marginBottom: '20px' })}
`;

export const Hr = styled.hr`
  background-color: #b4b4b4;
  border: none;
  height: 1px;
  width: 80%;
`;

export const Summary = styled.div`
  flex: 1;
  background: #fefefe;
  border-radius: 10px;
  height: max-content;
  margin-top: 30px;
  box-shadow: 0 1px 2px 1px #a3d4cb;
`;
export const OrderImgWrap = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  height: 65vh;
  justify-content: center;
`;
export const OrderImage = styled.img`
  width: 100%;
  height: 65vh;
  object-fit: cover;
`;

export const SummaryTitle = styled.h1`
  font-weight: 400;
  font-size: 30px;
  font-family: oswald;
`;

export const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === 'total' && '500'};
  font-size: ${(props) => props.type === 'total' && '24px'};
`;

export const SummaryItemText = styled.span`
  font-weight: 400;
`;

export const SummaryItemPrice = styled.span`
  font-weight: 500;
`;

export const CheckOutButton = styled(Link)`
  background: #1d9682;
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    transition: all 0.2sease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
  ${mobileS({ padding: '10px 75px' })}
  ${desktop({ padding: '10px 160px' })}
`;
export const BacktoShopBtn = styled(Link)`
  background: #1d9682;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  font-family: oswald;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  &:hover {
    transition: all 0.2sease-in-out;
    text-decoration: none;
    background: #f5f5f5;
    color: #205072;
  }
`;
const ImgContainer = styled.div`
    display: none;
    
  @media screen and (min-width: 768px) {
    display: block;
   
  }
`;
const ImgContainerM = styled.div`
    display: block;
    
  @media screen and (min-width: 768px) {
    display: none;
   
  }
`;

export const SetReminderButton = styled.button`
  background-color: #2e4158; /* Blue background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 10px 20px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change cursor to pointer */
  font-size: 16px; /* Set font size */
  margin-top: 10px; /* Margin on top */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  max-width: 100%; /* Ensures the button doesn't overflow its container */
  width: 100%; /* Button takes full width of the container */
  position: relative; /* Ensures proper positioning relative to the container */

  &:hover {
    background-color: #1d9682; /* Darker blue on hover */
    color: #e9ecef;
    outline: 1px solid #2e4158;
  }

  &:disabled {
    background-color: #cccccc; /* Gray background for disabled state */
    cursor: not-allowed; /* Change cursor to not-allowed */
  }
`;